<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.themeSetting.title') }}
          -
          {{ $t('settings.project.projectType.themeSetting.direct_top2.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <component
      v-for="(themeConfigItem, index) in themeFieldConfig"
      :key="index"
      :is="themeConfigItem.fieldType"
      :handle="themeConfigItem.handle"
      :label="themeConfigItem.title"
      :desc="themeConfigItem.desc"
      :value-from-db="themeValues[themeConfigItem.handle]"
      @update-field="updateSettingValue($event, themeConfigItem.handle)"
    />
  </div>
</template>

<script>
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import InputRow from '@/components/helper/InputRow.vue';

export default {
  name: 'ThemeSettingsDirectTop2',
  components: {
    CheckboxRow,
    ImageUploadRow,
    InputRow,
  },
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      themeFieldConfig: [
        {
          handle: 'logoimage',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.logoimage.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.logoimage.desc'),
          fieldType: 'ImageUploadRow',
        },
        {
          handle: 'show_logo',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.show_logo.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },

        {
          handle: 'hideNavOnScroll',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.hideNavOnScroll.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.hideNavOnScroll.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'hideNavScrollOnlyOnMobile',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.hideNavScrollOnlyOnMobile.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.hideNavScrollOnlyOnMobile.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navOnTop',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.navOnTop.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.navOnTop.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'background_color',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.background_color.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.background_color.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'color',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.color.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.color.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'showPaginationDesktop',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.showPaginationDesktop.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.showPaginationDesktop.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'hidePaginationOnScroll',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.hidePaginationOnScroll.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.hidePaginationOnScroll.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'hidePagination',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.hidePagination.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.hidePagination.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'maxWidth',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.maxWidth.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.maxWidth.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'maxWidthBackgroundColor',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.maxWidthBackgroundColor.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.maxWidthBackgroundColor.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'title',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.title.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.title.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'article',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.article.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.article.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'menuTags',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.menuTags.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.menuTags.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'scrollMenu',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.scrollMenu.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.scrollMenu.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'search',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.search.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.search.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'shadow',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.shadow.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.shadow.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'pagination',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.pagination.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.pagination.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'show_borders',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.show_borders.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.show_borders.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_hide',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation_hide.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation_hide.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_position',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation_position.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.navigation_position.desc'),
          fieldType: 'CheckboxRow',
        },

        {
          handle: 'sharing',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.sharing.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'sharing_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.sharing_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.sharing_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'cookies',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.cookies.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'cookies_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.cookies_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.cookies_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'facebook',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.facebook.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.facebook.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'facebook_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.facebook_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.facebook_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'twitter',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.twitter.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.twitter.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'twitter_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.twitter_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.twitter_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'instagram',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.instagram.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.instagram.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'instagram_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.instagram_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.instagram_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'linkedin',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.linkedin.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.linkedin.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'linkedin_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.linkedin_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.linkedin_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'xing',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.xing.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.xing.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'xing_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.xing_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.xing_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'youtube',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.youtube.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.youtube.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'youtube_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.youtube_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.youtube_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'website',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.website.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'website_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.website_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.website_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'print',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.print.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'print_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.print_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.print_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'overview',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.overview.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'overview_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.overview_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.overview_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.imprint.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.imprint_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.imprint_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.dataprivacy.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.dataprivacy_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.dataprivacy_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'custom1',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom1.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'custom1_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom1_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.custom1_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'custom1_icon',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom1_icon.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.custom1_icon.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'custom2',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom2.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'custom2_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom2_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.custom2_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'custom2_icon',
          title: this.$t('settings.project.projectType.themeSetting.direct_top2.custom2_icon.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_top2.custom2_icon.desc'),
          fieldType: 'InputRow',
        },
      ],
      themeValues: {
        logoimage: {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        },
        title: null,
        hideNavOnScroll: false,
        hideNavScrollOnlyOnMobile: false,
        showPaginationDesktop: false,
        hidePaginationOnScroll: false,
        hidePagination: false,
        background_color: null,
        color: null,
        maxWidth: null,
        maxWidthBackgroundColor: null,
        navOnTop: true,
        article: false,
        menuTags: false,
        show_logo: true,
        scrollMenu: false,
        search: false,
        navigation: true,
        navigation_hide: false,
        navigation_position: false,
        show_borders: false,
        shadow: false,
        pagination: false,
        sharing: true,
        sharing_tooltip: null,
        cookies: true,
        cookies_tooltip: null,
        facebook: null,
        facebook_tooltip: null,
        twitter: null,
        twitter_tooltip: null,
        instagram: null,
        instagram_tooltip: null,
        linkedin: null,
        linkedin_tooltip: null,
        xing: null,
        xing_tooltip: null,
        youtube: null,
        youtube_tooltip: null,
        website: null,
        website_tooltip: null,
        print: null,
        print_tooltip: null,
        overview: null,
        overview_tooltip: null,
        imprint: null,
        imprint_tooltip: null,
        dataprivacy: null,
        dataprivacy_tooltip: null,
        custom1: null,
        custom1_tooltip: null,
        custom1_icon: 'fa-heart',
        custom2: null,
        custom2_tooltip: null,
        custom2_icon: 'fa-heart',
      },
    };
  },
  created() {
    // add the values to the data
    if (this.settingValues) {
      // eslint-disable-next-line array-callback-return
      Object.entries(this.settingValues).map((itemValue) => {
        const key = itemValue[0];
        const value = itemValue[1];
        if (this.themeValues.hasOwnProperty(key)) {
          this.themeValues[key] = value;
        }
      });
      if (this.themeValues.logoimage.url) {
        if (!this.$checkForCloudImage(this.themeValues.logoimage.url)) {
          this.themeValues.logoimage.url = this.$makeCloudImage(
            this.themeValues.logoimage.url,
            { width: 450, height: 300 },
            this.themeValues.logoimage,
          );
        }
      }
    }
    this.$emit('update-theme-settings', this.themeValues);
  },
  methods: {
    updateSettingValue(value, handle) {
      if (this.themeValues.hasOwnProperty(handle)) {
        this.themeValues[handle] = value;
        this.$emit('update-theme-settings', this.themeValues);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
